<template>
  <div class="loginView bg-dark">
    <div class="card loginForm">
      <div class="card-body">
        <form @submit.prevent="signIn">
          <h2 class="text-center mb-5">
            eduBudget
          </h2>
          <div class="form-group">
            <label>Email address</label>
            <input
              v-model="form.email"
              type="email"
              class="form-control"
              placeholder="Enter email"
            >
          </div>
          <div class="form-group">
            <label>Password</label>
            <input
              v-model="form.password"
              type="password"
              class="form-control"
              placeholder="Password"
            >
          </div>

          <div class="d-flex flex-column text-center">
            <button
              type="submit"
              class="btn btn-outline-primary"
            >
              Sign In
            </button>

            <small class="py-2">- or -</small>

            <router-link
              to="/"
              class="text-danger"
            >
              Back to Home Page
            </router-link>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  data: () => ({
    form: {
      email: null,
      password: null,
    },
  }),
  methods: {
    ...mapActions(['login']),
    signIn() {
      this.login(this.form).then(() => {
        this.$router.push({ name: 'academies' });
      }).catch(this.showError);
    },
  },
};
</script>

<style>
.loginForm {
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
}

.loginView {
  display: flex;
  align-items: center;
  justify-content: center
}
</style>
